import React, { useRef, useEffect } from "react";
import lottie from "lottie-web";
import AnimationData from "./thanku.json";

const LottieAnimationComponent = () => {
  const containerRef = useRef(null);
  let anim = null;

  useEffect(() => {
    if (containerRef.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      anim = lottie.loadAnimation({
        container: containerRef.current,
        animationData: AnimationData,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });
    }

    return () => {
      if (anim) {
        anim.destroy();
      }
    };
  }, []);

  return (
    <div ref={containerRef} style={{ width: "80%", margin: "0 auto" }}></div>
  );
};

export default LottieAnimationComponent;
