import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import queryString from "query-string";


export default function Subscribed() {

  const [apiData, setApiData] = useState();

  const code = '';
  const { id } = queryString.parse(window.location.search);


  const fetchData = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}images/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setApiData(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }

  }

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return (
    <div className={styles.main}>
      {apiData?.data?.image_url && 
        <img src={apiData?.data?.image_url} className={styles.mainImg}/>
      }     
    </div>
  );
}
