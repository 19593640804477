import React, { useState, useEffect } from "react";
import queryString from "query-string";
import PromosheetView from "./PromosheetView";
import { useNavigate } from "react-router-dom";

const Promosheet = () => {
  const { id } = queryString.parse(window.location.search);
  const navigate = useNavigate();

  const [apiData, setApiData] = useState();

  const fetchData = async (id = 65) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}promosheets/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setApiData(data.data);
      // setCampaignDatas(data.data);
      //setProducts(data.data.products);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      navigate("/page-not-found");
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return apiData ? (
    <PromosheetView apiData={apiData} />
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100dvh",
      }}
    >
      <span className="loader"></span>
    </div>
  );
};

export default Promosheet;
