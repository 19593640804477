import React, { useState } from "react";
import ImageBox from "../image-box/ImageBox";
import BackgroundComponents from "../background-components/BackgroundComponents";
import NumberForm from "./NumberForm";
import PloygonIcon from "../../icons/Icons";
import Thanku from "../thanku-screen/ThankuOptOut";

function MySubscription() {
  const [sentStatus, setSentStatus] = useState(false);
  const [dnd, setDnd] = useState(false);

  const hundelChangeSentStatus = () => {
    setSentStatus(false);
  }

  if (sentStatus) {
    return <Thanku  dnd={dnd}/>;
  }

  return (
    <>    
    <div className="App">
      <BackgroundComponents />
      <div className="main">
        <div className="left">          
          <h1>
            Exclusive Savings <br /> Ahead!
            <span className="polygon">
              <PloygonIcon />
            </span>
          </h1>
          <p className="sub-heading">
            Sign up now to unlock special offers and discounts only for our
            subscribers.
          </p>
        </div>
        <div className="right">
          <NumberForm setSentStatus={setSentStatus} setDnd={setDnd} />
        </div>
      </div>
      <ImageBox />
    </div>
    </>
  );
}

export default MySubscription;
