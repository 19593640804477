import React, { useState, useEffect } from "react";
import "./campaign.css";
import ProductCard from "./ProductCard";
import mobileFormat from "../mobileFormat";

const PromosheetView = ({ apiData }) => {
  const [campaignDatas, setCampaignDatas] = useState(apiData);
  const [products, setProducts] = useState(apiData?.products);
  const [isPdf, setIsPdf] = useState(false);
  const [activeCategory, setActiveCategory] = useState(-1);
  const date = new Date().getFullYear();

  const showAllProduct = {
    icon: {
      id: 1,
      name: "cereal_160x160.png",
      image_url: "/images/campaign/showall-icon.svg",
    },
    category_name: "Show All",
    category_id: -1,
    products: [],
  };
  let pcount = -1;

  useEffect(() => {
    if (apiData) {
      setCampaignDatas((prevData) => {
        if (prevData?.products?.length > 1) {
          const showAllProductExists = prevData?.products.some(
            (product) => product.category_id === showAllProduct.category_id
          );

          if (!showAllProductExists) {
            return {
              ...prevData,
              products: [showAllProduct, ...prevData?.products],
            };
          } else {
            return {
              ...prevData,
              products: [...prevData?.products],
            };
          }
        }
        setActiveCategory(prevData?.products?.[0]?.category_id);
        return prevData;
      });
      setIsPdf(apiData?.full_page_banner?.image_url.endsWith(".pdf"));
    }
  }, [apiData]);

  const handleProductList = (id) => {
    setActiveCategory(id);
    setProducts(
      apiData?.products?.filter((item) =>
        id === -1 ? item : item.category_id === id
      )
    );
  };

  const calculateBrightness = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  };
  const isBackgroundColorDark = (hexColor) => {
    const brightness = calculateBrightness(hexColor);
    return brightness > 128;
  };
  const backgroundColor = apiData?.text_banner_background_color
    ? apiData?.text_banner_background_color
    : "#00000000";
  const textColor = isBackgroundColorDark(backgroundColor)
    ? "#000000"
    : "#ffffff";

  return (
    <>
      {isPdf ? (
        <div className="iframe-container">
          <iframe src={`https://docs.google.com/gview?embedded=true&url=${apiData?.full_page_banner?.image_url}`} />
        </div>
        // <object
        //   data={`https://docs.google.com/gview?embedded=true&url=${apiData?.full_page_banner?.image_url}`}
        //   type="application/pdf"
        //   frameborder="0"
        //   className="iframe-container"
        // >
        //   <embed
        //     src={`https://docs.google.com/gview?embedded=true&url=${apiData?.full_page_banner?.image_url}`}
        //     width="100%"
        //     height="100vh"
        //   />
        // </object>
      ) : (
        <div className="campaign_container">
          <header className="campaign_header">
            <div className="campaign_logo">
              {apiData?.logo?.image_url && (
                <img
                  src={apiData?.logo?.image_url}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "120px",
                  }}
                />
              )}
            </div>

            <div className="campaign_banner_container">
              <img
                src={apiData?.master_banner?.image_url}
                className="campaign_banner"
              />
            </div>
          </header>

          <div style={{ textAlign: "center" }}>
            <h1 className="title">{apiData?.main_title}</h1>
            <p className="sub_title">{apiData?.sub_title}</p>
          </div>

          <div className="category_container">
            {apiData?.products?.[0]?.category_id && (
              <p className="category_name">Categories</p>
            )}

            <div
              className="categories_list"
              style={{
                justifyContent:
                  campaignDatas?.products?.length <= 5 ? "center" : "",
                gap: campaignDatas?.products?.length <= 5 ? "80px" : "",
              }}
            >
              {campaignDatas?.products?.map(
                (list) =>
                  apiData?.products?.[0]?.category_id && (
                    <div
                      className="category_list_container"
                      style={{
                        minWidth:
                          campaignDatas?.products?.length <= 5 ? "auto" : "",
                      }}
                      key={list?.icon?.id}
                      onClick={() => handleProductList(list.category_id)}
                    >
                      {list?.icon?.image_url ? (
                        <img
                          className="categories_list_image"
                          src={list?.icon?.image_url}
                        />
                      ) : (
                        <img
                          className="categories_list_image"
                          src={"/images/campaign/vegetables-icon.svg"}
                        />
                      )}
                      <p
                        className="category_list_name"
                        style={{
                          color:
                            activeCategory === list.category_id
                              ? "#326C15"
                              : "",
                          fontWeight:
                            activeCategory === list.category_id ? "600" : "400",
                        }}
                      >
                        {list.category_name}
                      </p>
                    </div>
                  )
              )}
            </div>

            <div className="product_card_container">
              <div className="product_card_responsive">
                {products?.map((category) =>
                  category.products.map((item) => {
                    pcount++;
                    return (
                      <div
                        key={item.mktg_promo_sheet_product_id}
                        className="product_container"
                        style={{
                          borderRight:
                            pcount % 3 === 2 ? "" : "1px solid #E5E7EB",
                          borderBottom: "1px solid #F1F1F1",
                          width: "33.333%",
                        }}
                      >
                        <ProductCard item={item} />
                      </div>
                    );
                  })
                )}
              </div>
            </div>

            <div className="banner_container">
              <div className="half_banner_1">
                <a
                  href={apiData?.half_banners?.[0]?.half_banner_link}
                  title={apiData?.half_banners?.[0]?.half_banner_link}
                  target="_blank"
                >
                  <img
                    src={apiData?.half_banners?.[0]?.image_id?.image_url}
                    className="half_banner"
                  />
                </a>
              </div>

              <div className="half_banner_2">
                <a
                  href={apiData?.half_banners?.[1]?.half_banner_link}
                  title={apiData?.half_banners?.[1]?.half_banner_link}
                  target="_blank"
                >
                  <img
                    src={apiData?.half_banners?.[1]?.image_id?.image_url}
                    className="half_banner"
                  />
                </a>
              </div>
            </div>

            {apiData?.text_banner_subheading ||
            apiData?.text_banner_subheading ||
            apiData?.text_banner_button_link ? (
              <div
                className="banner_text_container"
                style={{
                  backgroundColor: backgroundColor,
                  border: `${
                    backgroundColor === "#00000000" || null
                      ? "1px solid #CCCCCC"
                      : "0px"
                  }`,
                }}
              >
                {apiData?.text_banner_heading && (
                  <p
                    className="banner_text_title"
                    style={{
                      color:
                        backgroundColor === "#00000000" ? "#000000" : textColor,
                    }}
                  >
                    {apiData?.text_banner_heading}
                  </p>
                )}
                {apiData?.text_banner_subheading && (
                  <p
                    className="banner_text_subtitle"
                    style={{
                      color:
                        backgroundColor === "#00000000" ? "#000000" : textColor,
                    }}
                  >
                    {apiData?.text_banner_subheading}
                  </p>
                )}
                {apiData?.text_banner_button_link && (
                  <a href={apiData?.text_banner_button_link} target="_blank">
                    <button className="banner_sub_button">
                      {apiData?.text_banner_button}
                    </button>
                  </a>
                )}
              </div>
            ) : (
              <></>
            )}

            <div className="full_banner" title={apiData?.full_page_banner_link}>
              <a href={apiData?.full_page_banner_link} target="_blank">
                <img
                  src={apiData?.full_page_banner?.image_url}
                  className="full_banner_img"
                />
              </a>
            </div>
          </div>

          <footer className="campaign_footer">
            {apiData?.footer_description ||
            apiData?.footer_social_link_facebook ||
            apiData?.footer_contact_info ||
            apiData?.footer_links?.length > 0 ? (
              <div className="campaign_footer_container">
                {apiData?.footer_description ||
                apiData?.footer_social_link_facebook ||
                apiData?.footer_social_link_twitter ||
                apiData?.footer_social_link_instagram ? (
                  <div className="footer_social">
                    {apiData?.logo?.image_url && (
                      <div className="footer_logo_image">
                        <img
                          src={apiData?.logo?.image_url}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    )}

                    <p className="footer_description">
                      {apiData?.footer_description}
                    </p>

                    <div style={{ display: "flex", gap: "24px" }}>
                      {apiData?.footer_social_link_twitter && (
                        <a
                          href={apiData?.footer_social_link_twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/images/campaign/twitter-icon.svg"
                            alt="twitter"
                            className="social_icon"
                          />
                        </a>
                      )}
                      {apiData?.footer_social_link_facebook && (
                        <a
                          href={apiData?.footer_social_link_facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/images/campaign/facebook-icon.svg"
                            alt="facebook"
                            className="social_icon"
                          />
                        </a>
                      )}

                      {apiData?.footer_social_link_instagram && (
                        <a
                          href={apiData?.footer_social_link_instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/images/campaign/instagram-icon.svg"
                            alt="instagram"
                            className="social_icon"
                          />
                        </a>
                      )}
                    </div>

                    <p className="footer_copyright_text">
                      © {date} Apni Mandi. All rights reserved.
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                {apiData.footer_contact_info &&
                  apiData.footer_contact_info_email &&
                  apiData.footer_contact_info_phone && (
                    <div className="contact_info">
                      <p className="footer_text">Contact Info</p>
                      <a
                        href={`https://maps.google.com/?q=${apiData.footer_contact_info}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer_address"
                      >
                        {apiData.footer_contact_info}
                      </a>
                      <a href={`mailto:${apiData.footer_contact_info_email}`}>
                        Email: {apiData.footer_contact_info_email}
                      </a>
                      <a href={`tel:+1 ${apiData.footer_contact_info_phone}`}>
                        Phone: +1{" "}
                        {mobileFormat(apiData.footer_contact_info_phone)}
                      </a>
                    </div>
                  )}
                {apiData?.footer_links?.length > 0 && (
                  <div className="more_links">
                    <p className="footer_text">More Links</p>
                    {apiData?.footer_links.map((link) => (
                      <a
                        href={link.footer_button_link}
                        target="_blank"
                        key={link.mktg_promo_sheet_footer_id}
                      >
                        {link.footer_button_text}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}

            <p className="footer_copyright_mobile">
              © {date} Apni Mandi. All rights reserved.
            </p>
          </footer>
        </div>
      )}
    </>
  );
};

export default PromosheetView;
