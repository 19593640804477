import { useState } from "react";
import "./App.css";
import BackgroundComponents from "./components/background-components/BackgroundComponents";
import ImageBox from "./components/image-box/ImageBox";
import LoginForm from "./components/login-form/LoginForm";
import PloygonIcon from "./icons/Icons";
import { Link } from "react-router-dom";
import Thanku from "./components/thanku-screen/Thanku";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  const [sentStatus, setSentStatus] = useState(false);

  const hundelChangeSentStatus = () => {
    setSentStatus(false);
  }
  
  if (sentStatus) {
    return <Thanku  hundelChangeSentStatus={hundelChangeSentStatus}/>;
  }
  return (
    <>
    <GoogleReCaptchaProvider reCaptchaKey="6LcLGpooAAAAAFtYkqzVVCeq-oAKMNH-hagguY8C">
    <div className="App">
      <BackgroundComponents />
      <div className="main">
        <div className="left">
          {sentStatus && (
            <Link to={"/"}>
              <img src="/images/logo.png" alt="logo" />
            </Link>
          )}
          <h1>
            Exclusive Savings <br /> Ahead!
            <span className="polygon">
              <PloygonIcon />
            </span>
          </h1>
          <p className="sub-heading">
            Sign up now to unlock special offers and discounts only for our
            subscribers.
          </p>
        </div>
        <div className="right">
          <LoginForm sentStatus={sentStatus} setSentStatus={setSentStatus} />
        </div>
      </div>
      <ImageBox />
    </div>
    </GoogleReCaptchaProvider>
    </>
  );
}

export default App;
