import { Link } from "react-router-dom";
import BackgroundComponents from "../background-components/BackgroundComponents";
import ImageBox from "../image-box/ImageBox";
import styles from "./style.module.css";

export default function Thanku({ dnd }) {
  return (
    <div className={styles.main}>
      <BackgroundComponents />
      <ImageBox ishome={true} />
      <div>
        <Link to="/">
          <img
            src="/images/logo.png"
            alt="logo"
            style={{ cursor: "pointer" }}
          />
        </Link>
        {dnd ? (
          <p className={styles.sub}>
            You have been successfully opted out and will no longer receive
            messages
          </p>
        ) : (
          <p className={styles.sub}>
            You have successfully updated the subscription.
          </p>
        )}
      </div>
      {/* <div className={styles.footer}>
      <span>Message & data rates may apply |&nbsp;
               <Link to="https://app.termly.io/document/terms-of-service/5f4aa577-fcd5-4411-86b5-f7610a4f6cec" target="_blank">Terms and Service.</Link></span>
      </div> */}
    </div>
  );
}
