import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

const ModalImage = ({ cardData, isModalOpen, closeModal }) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      PaperProps={{
        style: {
          margin: "16px",
          borderRadius: "16px",
          width:"100%",
          maxWidth:"400px",
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
      }}
      onClick={closeModal}
    >
      <DialogTitle sx={{ padding: "5px 5px 0px 5px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span></span>
          <IconButton aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ padding: "0px 12px 12px 12px" }}>
        <div style={{ minWidth: "300px", width:"100%", textAlign: "center" }}>
        <div style={{width:"100%",}}>
          <img
            src={cardData?.image_id?.image_url}
            alt="Item Image"
            className="modalImg"
          />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "112px",
                overflow: "hidden",
                textAlign: "center",
                margin: "0 auto"
              }}
            >
              <p
                className="product_name"
                style={{ fontSize: "26px", margin: "0", }}
              >
                {cardData?.product_name}
              </p>
              <p
                className="product_weight"
                style={{
                  fontSize: "19px",
                  fontWeight: "500",
                  margin: "5px 0 0",
                  maxWidth: "100%"
                }}
              >
                {cardData?.product_weight} {cardData?.product_uom}
              </p>
            </div>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <p
                className="product_price"
                style={{
                  fontSize: "54px",
                  fontWeight: "600",
                  verticalAlign: "middle",
                }}
              >
                ${cardData?.product_sale_price + " "}
                {cardData?.product_regular_price && (
                  <span
                    style={{
                      color: "#6B7280",
                      fontWeight: "400",
                      fontSize: "18px",
                      textDecoration: "line-through",
                      verticalAlign: "middle",
                    }}
                  >
                    (${cardData?.product_regular_price})
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalImage;
