import React, { useEffect } from "react";
import BackgroundComponents from "../background-components/BackgroundComponents";
import ImageBox from "../image-box/ImageBox";
import LottieAnimationComponent from "../lottie-animation/LottieAnimation";
import styles from "./styles.module.css";
import queryString from "query-string";
import { Link } from "react-router-dom";

export default function Subscribed() {

  const { code } = queryString.parse(window.location.search);

  useEffect(() => {
    const res = fetch(
      `${process.env.REACT_APP_API_URL}mktg-subscriptions/confirmation/${code}`,
      {
        method: "PATCH",
        //body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }, [code]);

  return (
    <div className={styles.main}>
      <BackgroundComponents />
      <ImageBox ishome={true} />
      <div>
        <Link to="/"><img src="/images/logo.png" alt="logo" /></Link>
        <div className={styles.animation}>
          <LottieAnimationComponent />
        </div>
        <h2 className={styles.mainheading}>
          You've subscribed to <span>ApniMandi!</span>
        </h2>
        <h2 className={styles.mainheading}>
          Share with your family and friends to help them save
        </h2>
        <div style={{textAlign: "center", marginTop: "-30px"}}>
          <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.apnimandi.com" target="_blank" rel="noopener">
            <img src="/images/Facebook.svg" alt="Facebook" />
          </a>
          <a target="_blank" href="https://twitter.com/home?status=https://www.apnimandi.com" title="twitter">
            <img src="/images/Twitter.svg" alt="Twitter" />
          </a>
          <a target="_blank" href="https://telegram.me/share/url?url=https://www.apnimandi.com&text=Apni Mandi" data-action="share/whatsapp/share">
            <img src="/images/Telegram.svg" alt="Telegram" />
          </a>
        </div>
      </div>
    </div>
  );
}
