const PloygonIcon = () => {
  return (
    <svg
      width="52"
      height="55"
      viewBox="0 0 52 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.36563 25.0635L15.1885 0.969623L27.3189 36.1213L2.36563 25.0635Z"
        fill="#F47820"
      />
      <path
        d="M24.3076 7.71625L51.2684 3.46692L30.4839 34.3019L24.3076 7.71625Z"
        fill="#F47820"
      />
      <path
        d="M4.06029 54.1573L0.252874 30.3715L24.6678 41.6838L4.06029 54.1573Z"
        fill="#F47820"
      />
    </svg>
  );
};

export default PloygonIcon;
