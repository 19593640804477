import styles from "./style.module.css";

export default function BackgroundComponents() {
  return (
    <>
      <img
        className={styles.img1}
        src="/images/background/img1.png"
        alt="background"
      />
      <img
        className={styles.img2}
        src="/images/background/img2.png"
        alt="background"
      />
      <img
        className={styles.img3}
        src="/images/background/img3.png"
        alt="background"
      />
    </>
  );
}
