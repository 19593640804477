import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ErrorBoundary from "../../ErrorBoundary";
import React, { forwardRef, useState } from "react";

const PREFIX = "CustomInput";

const classes = {
  inputFormRoot: `${PREFIX}-inputFormRoot`,
  inputLabelRoot: `${PREFIX}-inputLabelRoot`,
  inputLabelFormControl: `${PREFIX}-inputLabelFormControl`,
  outlinedInput: `${PREFIX}-outlinedInput`,
  notchedOutline: `${PREFIX}-notchedOutline`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.inputFormRoot}`]: {
    display: "flex",
  },

  [`& .${classes.inputLabelRoot}`]: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#464E5F",
    lineHeight: "1rem",
  },

  [`& .${classes.inputLabelFormControl}`]: {
    position: "static",
    transform: "inherit",
    textAlign: "left",
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.notchedOutline}`]: {
    border: "none",
    width: "100%",
  },
  [`& .${classes.outlinedTextField}`]: {
    background: "#ffffff",
    fontSize: "14px",
    fontWeight: "400",
    color: "#464E5F",
    borderRadius: "8px",
    width: "100%",
    padding: "0px",
  },
  [`& .${classes.outlinedInput}`]: {
    background: "#ffffff",
    fontSize: "14px",
    fontWeight: "400",
    color: "#464E5F",
    borderRadius: "8px",
  },
}));

/**
 * Bootstrap type input textfield
 * takes full width
 *
 * for hide / show password type input : use "LabelledTextFieldWithAdornments" component
 */

const CustomInput = forwardRef((props, ref) => {
  const {
    maxLength,
    maxChar,
    startAdornment,
    label,
    labelRequired,
    variant,
    type,
    error,
    helperText,
    className,
    inputRef,
    required,
    fullWidth,
    size,
    width,
    disabled,
    multiline,
    rows,
    ...restOfTextFieldProps
  } = props;

  return (
    <ErrorBoundary>
      <StyledFormControl
        classes={{
          root: classes.inputFormRoot,
        }}
        className={`${className} ${
          fullWidth ? "w-full" : width ? width : ""
        } mui-textfield`}
      >
        <InputLabel
          required={required}
          classes={{
            root: classes.inputLabelRoot,
            formControl: classes.inputLabelFormControl,
          }}
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#1A1009 !important",
          }}
          shrink
          disabled={disabled}
        >
          {label} {labelRequired && <span className="requiredStar">*</span>}
        </InputLabel>
        <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#667085",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#f47820",
              },
              "&:hover fieldset": {
                borderColor: "#f47820",
              },
            },
          }}
          type={type}
          className={classes.outlinedInput}
          fullWidth={fullWidth}
          {...restOfTextFieldProps}
          inputRef={inputRef}
          size={size}
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          inputProps={{
            maxLength: +maxLength,
            startAdornment: startAdornment,
          }}
        />
        <FormHelperText error={Boolean(error)} margin="dense" variant={variant}>
          {helperText}
        </FormHelperText>
      </StyledFormControl>
    </ErrorBoundary>
  );
});

CustomInput.defaultProps = {
  maxLength: 1000,
  label: "",
  labelRequired: false,
  variant: "outlined",
  ref: null,
  type: "text",
  error: false,
  helperText: "",
  className: null,
  inputRef: null,
  required: false,
  fullWidth: true,
  size: "medium",
  disabled: false,
  multiline: false,
  rows: 1,
};

export default CustomInput;
