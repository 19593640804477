import React, { useState, useEffect } from "react";
import CustomInput from "../form-components/custom-input/CustomInput";
import styles from "./style.module.css";
import PloygonIcon from "../../icons/Icons";
import { Link } from "react-router-dom";
import CustomSelect from "../form-components/custom-seclect/CustomSelect";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import mobileFormat from "../mobileFormat";
import { Divider, Checkbox, FormControlLabel } from "@mui/material";

//Default values
const defaultValues = {
  customer_zipcode: "",
  customer_mobile: "",
  store_id: null,
  customer_name: "",
  customer_email: "",
  mktg_subscription_id: "",
  customer_source: "",
  is_dnd: false,
};
//Yup Schema definition
const FormSchema = yup.object().shape({
  customer_zipcode: yup
    .string()
    .required("Zipcode is required")
    .min(5, "Please enter a valid zipcode"),
  customer_mobile: yup
    .string()
    .required("Mobile number is required")
    .min(10, "Please enter a valid number"),
  store_id: yup.object().required("Preferred store is required").nullable(),
  customer_email: yup.string().when({
    is: (exists) => !!exists,
    then: yup.string().email("Please enter a valid email").nullable(),
    otherwise: yup.string().nullable(),
  }),
});

function HandleErrorResponse(response, setError) {
  if (response.errors) {
    response.errors.forEach((error) => {
      const field = error.field;
      const message = error.message;
      if (field && message) {
        setError(field, {
          message: message,
        });
      } else {
        console.log("Unknown error occurred.");
      }
    });
  } else {
    console.log("Unknown error response.");
  }
}

export default function UpdateForm({
  countryCode,
  mobile,
  setSentStatus,
  setDnd,
}) {
  const [mappedState, setMappedState] = useState({});
  const [allStateList, setAllStateList] = useState([]);

  const fetchStoreData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}stores`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const new_array = [];
      if (data?.data) {
        data?.data.forEach((sr) => {
          const label = `${sr.store_name}, ${sr?.city} , ${sr?.state}`;
          const str2 = { id: sr.store_id, name: label };
          new_array.push(str2);
        });
        setAllStateList(new_array);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchSubscribedData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}mktg-subscriptions/mobile/${countryCode}${mobile}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const userData = await response.json();
      if (userData?.data) {
        setValue("customer_mobile", mobile);
        setValue("customer_zipcode", userData?.data?.customer_zipcode);
        const label = `${userData?.data?.store_name}, ${userData?.data?.city} , ${userData?.data?.state}`;
        const str2 = { id: userData?.data?.store_id, name: label };
        setValue("store_id", str2);
        setValue("customer_name", userData?.data?.customer_name);
        setValue("customer_email", userData?.data?.customer_email);
        setValue(
          "mktg_subscription_id",
          userData?.data?.mktg_subscription_id
        );
        setValue("customer_source", userData?.data?.customer_source);
        setValue(
          "is_dnd",
          userData?.data?.is_dnd || false
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchStoreData();
    fetchSubscribedData();
  }, []);

  //useForm Hook
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: defaultValues,
  });

  const {
    customer_mobile,
    customer_zipcode,
    store_id,
    customer_name,
    customer_email,
    mktg_subscription_id,
    customer_source,
    is_dnd,
  } = watch();

  useEffect(() => {
    if (allStateList.length > 0) {
      let tempMappedNames = {};
      allStateList.forEach((center) => {
        const { id, ...rest } = center;
        tempMappedNames[id] = { ...rest };
      });
      setMappedState(tempMappedNames);
    } else {
      setMappedState({});
    }
  }, [allStateList]);

  //handlers
  const handleChangeValue = (field, value) => {
    if (field === "customer_mobile") {
      const pvalue = ("" + value).replace(/\D/g, "").slice(0, 10);
      setValue(field, pvalue, { shouldValidate: true });
    } else {
      setValue(field, value, { shouldValidate: true });
    }
  };

  const handleSubmitForm = async (formData) => {
    const formDataJson = {
      country_itu: countryCode,
      customer_mobile: formData.customer_mobile,
      customer_zipcode: formData.customer_zipcode,
      store_id: formData.store_id?.id.toString(),
      customer_name: formData.customer_name || null,
      customer_email: formData.customer_email || null,
      customer_source: customer_source,
      is_dnd: formData.is_dnd,
    };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}mktg-subscriptions/${mktg_subscription_id}`,
        {
          method: "PATCH",
          body: JSON.stringify(formDataJson),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();
      if (data?.errors?.length > 0) {
        HandleErrorResponse(data, setError);
      } else if (data?.code === 200) {
        setSentStatus(true);
        setDnd(data?.data?.dnd_status == "Yes" ? true : false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={styles.login}>
        <div className={styles.main}>
          <div className={styles.header}>
            <Link to="/">
              <img src="/images/logo.png" alt="logo" />
            </Link>
            <h1>
              Exclusive Savings <br /> Ahead!
              <span className="polygon">
                <PloygonIcon />
              </span>
            </h1>
            <p className="sub-heading">
              Sign up now to unlock special offers and discounts only for our
              subscribers.
            </p>
          </div>

          <form id="add-form" onSubmit={handleSubmit(handleSubmitForm)}>
            <CustomInput
              disabled={true}
              label="Mobile Number"
              labelRequired={true}
              type="text"
              placeholder="Enter mobile number"
              error={Boolean(errors.customer_mobile)}
              helperText={
                errors?.customer_mobile && errors?.customer_mobile?.message
              }
              value={mobileFormat(customer_mobile)}
              onChange={(e) => {
                handleChangeValue(
                  "customer_mobile",
                  e?.target?.value?.trimStart().replace(/\D/, "")
                );
              }}
              inputRef={register("customer_mobile").ref}
              capitalize={true}
            />
            <CustomInput
              label="Zipcode"
              labelRequired={true}
              type="text"
              placeholder="Enter zipcode"
              error={Boolean(errors.customer_zipcode)}
              helperText={
                errors?.customer_zipcode && errors?.customer_zipcode?.message
              }
              value={customer_zipcode}
              onChange={(e) => {
                handleChangeValue(
                  "customer_zipcode",
                  e?.target?.value?.trimStart().replace(/\D/, "")
                );
              }}
              maxLength={5}
              inputRef={register("customer_zipcode").ref}
              capitalize={true}
            />
            <CustomSelect
              openOnFocus={false}
              label="Preferred Store"
              labelRequired={true}
              placeholder="Select your preferred store"
              options={allStateList}
              getOptionLabel={(option) => {
                const displayName =
                  (typeof option === "object" &&
                    (option.label || option.name)) ||
                  (typeof option === "string" &&
                    `${option}` in mappedState &&
                    mappedState[`${option}`].name) ||
                  "";
                return displayName;
              }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  handleChangeValue("store_id", null);
                } else if (newValue?.id) {
                  handleChangeValue("store_id", newValue);
                }
              }}
              value={store_id}
              isOptionEqualToValue={(option, value) => option.id === value}
              inputRef={register("store_id").ref}
              error={Boolean(errors.store_id)}
              helperText={errors?.store_id && errors?.store_id?.message}
            />
            <div className="notName">
              <Divider sx={{ marginTop: "6px" }} />
              <CustomInput
                label="Name"
                type="text"
                placeholder="Enter your name"
                error={Boolean(errors.customer_name)}
                helperText={
                  errors?.customer_name && errors?.customer_name?.message
                }
                value={customer_name}
                onChange={(e) => {
                  handleChangeValue("customer_name", e?.target?.value);
                }}
                maxLength={70}
                inputRef={register("customer_name").ref}
                capitalize={true}
              />
              <CustomInput
                label="Email"
                type="text"
                placeholder="Enter email"
                error={Boolean(errors.customer_email)}
                helperText={
                  errors?.customer_email && errors?.customer_email?.message
                }
                value={customer_email}
                onChange={(e) => {
                  handleChangeValue("customer_email", e?.target?.value);
                }}
                maxLength={120}
                inputRef={register("customer_email").ref}
                capitalize={true}
              />
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  checked={is_dnd}
                  onChange={(e) => {
                    handleChangeValue("is_dnd", e?.target?.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  style={{ color: "#f47820" }}
                />
              }
              label={<span style={{ fontSize: "14px" }}>OPT OUT</span>}
            />

            <button className={styles["main-button"]}>Submit</button>
          </form>
        </div>

        {/* <div className={styles.footer}>
          <span>
            By submitting this form, you agree to receive recurring promotional
            and personalized marketing text messages. Consent is not a condition
            of any purchase.&nbsp;
            <Link
              to="https://app.termly.io/document/terms-of-service/5f4aa577-fcd5-4411-86b5-f7610a4f6cec"
              target="_blank"
            >
              Terms and Service.
            </Link>
          </span>
        </div> */}
      </div>
    </>
  );
}
