import React from "react";
import "./campaignexp.css";

const CampaignExpired = () => {

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "70vh" }}>
      <div style={{ width: "98%", maxWidth: "1100px", textAlign: "center", backgroundColor: "#F7F7F7", border:"3px solid #F0F0F0", padding: "50px 20px" }}>
        <div  className="campaignexpiredtext" style={{ flexDirection: "column", alignItems: "center", justifyContent: "center",}}>
          <div className="campaignexpiredheading" >We're Sorry!</div>
          <div style={{borderBottom:"1px solid #D4D4D4", height:"20px", width:"50%", margin: "auto"}}>&nbsp;</div>
          <div style={{paddingTop:"20px", paddingBottom:"16px"}}>This campaign has Expired.</div>
          <div>Stay alert for an upcoming campaign <br />announcement!</div>
          <div style={{borderBottom:"1px solid #D4D4D4", height:"30px", width:"50%", margin: "auto"}}>&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default CampaignExpired;