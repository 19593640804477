import styles from "./style.module.css";

export default function ImageBox({ ishome }) {
  return (
    <div className={ishome ? styles.home : styles.main}>
      <div className={styles.img1}>
        <img src="/images/slider/img3.svg" alt="slider" />
      </div>

      <div className={styles.img2}>
        <img src="/images/slider/img5.svg" alt="slider" />
      </div>
      <div className={styles.img3}>
        <img src="/images/slider/img6.svg" alt="slider" />
      </div>
      <div className={styles.img4}>
        <img src="/images/slider/img1.svg" alt="slider" />
      </div>

      <div className={styles.img5}>
        <img src="/images/slider/img2.svg" alt="slider" />
      </div>

      <div className={styles.img6}>
        <img src="/images/slider/img4.svg" alt="slider" />
      </div>
    </div>
  );
}
