import { Link } from "react-router-dom";
import styles from "./style.module.css";
import BackgroundComponents from "../background-components/BackgroundComponents";
import ImageBox from "../image-box/ImageBox";

export default function Home() {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.homeContainer}>
      <div
        className={styles.home}
        style={{
          backgroundImage: "url('/images/background/background-image.svg')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // aspectRatio: "26/9",
        }}
      >
        <div className={styles.greenLine}></div>

        <div className={styles.container}>
          <div className={styles.apnimandiContainer}>
            <img
              src="/images/home/apnimandi.svg"
              alt="apnimandi"
              className={styles.apnimandi}
            />

            <div className={styles.open24Container}>
              <img
                src="/images/home/open24.svg"
                alt="open24"
                className={styles.open24}
              />
            </div>
          </div>

          <img
            src="/images/home/granopening.svg"
            alt="grandopening"
            className={styles.grandopening}
          />

          <img
            src="/images/home/diwalisale.svg"
            alt="diwalisale"
            className={styles.diwalisale}
          />

          <div className={`flex flex-row `}>
            <Link className={styles.link} to="/signup">
              <button className={styles.subscribeButton}>Subscribe</button>
            </Link>
            <Link className={styles.link} to="/my-subscription">
              <button className={styles.mySubscribeButton}>
                My Subscription
              </button>
            </Link>
          </div>

          {/* <Link to="/signup">Subscribe</Link> */}
        </div>
      </div>

      <div className={styles.footercat}>
        <div className={styles.footer}>
          <div className={styles.footerImg}>
            <div>
              <img
                src="/images/home/location1.svg"
                alt="location1"
                className={styles.location1}
              />
            </div>
            <div>
              <img
                src="/images/home/location2.svg"
                alt="location2"
                className={styles.location2}
              />
            </div>
            <div>
              <img
                src="/images/home/location3.svg"
                alt="location3"
                className={styles.location3}
              />
            </div>
            <div>
              <img
                src="/images/home/location4.svg"
                alt="location4"
                className={styles.location4}
              />
            </div>
          </div>
        </div>

        <div className={styles.footerCopyRight}>
          <p>© {currentYear} Apni Mandi. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}
