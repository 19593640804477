import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";



export default function PageNotFound() {
 

  return (
    <div className={styles.main}>
      <div style={{width: "100%",maxWidth:"800px"}}>  
        <img src={"/images/404.svg"} className={styles.mainImg} alt="page not found"/>     
        </div> 
    </div>
  );
}
