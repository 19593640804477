import React, { useState } from "react";
import ModalImage from "./ModalImage";

const ProductCard = ({ item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardData, setCardData] = useState(null);

  const openModal = (item) => {
    setCardData(item);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="product_card">
      <div onClick={() => openModal(item)}>
        <img src={item.image_id.image_url} alt="" className="product_image" />
        <div className="product_details">
          <div>
            <p className="product_name" title={item.product_name}>
              {item.product_name}
            </p>
            <p
              className="product_weight"
              title={item.product_weight + " " + item.product_uom}
            >
              {item.product_weight} {item.product_uom}
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p
              className="product_price"
              title={`$${item.product_sale_price}`}
              style={{ verticalAlign: "middle" }}
            >
              ${item.product_sale_price + " "}
              {item.product_regular_price && (
                <span
                  style={{
                    color: "#6B7280",
                    fontWeight: "400",
                    fontSize: "60%",
                    textDecoration: "line-through",
                    verticalAlign: "middle"
                  }}
                >
                  (${item.product_regular_price})
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
      <ModalImage
        isModalOpen={isModalOpen}
        cardData={cardData}
        closeModal={closeModal}
      />
    </div>
  );
};

export default ProductCard;
