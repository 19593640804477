import React, { useState, useEffect, useRef } from "react";
import CustomInput from "../form-components/custom-input/CustomInput";
import styles from "./style.module.css";
import PloygonIcon from "../../icons/Icons";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import mobileFormat from "../mobileFormat";
import UpdateForm from "./UpdateForm";
import OTPInput from "react-otp-input";

const countryCode = "1";
//Default values
const defaultValues = {
  customer_mobile: "",
  otp: "",
};

//Yup Schema definition
const FormSchema = yup.object().shape({
  customer_mobile: yup
    .string()
    .required("Mobile number is required")
    .min(10, "Please enter a valid number"),
});

function HandleErrorResponse(response, setError) {
  if (response.errors) {
    response.errors.forEach((error) => {
      const field = error.field;
      const message = error.message;
      if (field && message) {
        setError(field, {
          message: message,
        });
      } else {
        console.log("Unknown error occurred.");
      }
    });
  } else {
    console.log("Unknown error response.");
  }
}

export default function NumberForm({ setSentStatus, setDnd }) {
  //useForm Hook
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: defaultValues,
  });

  const { customer_mobile, otp } = watch();

  const otpInputRefs = useRef([]);

  //States
  const [showOtpScreen, setShowOtpScreen] = useState(0);
  const [otpError, setOtpError] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(1000 * 1000);

  
  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (secondsRemaining === 0) {
      setIsActive(true);
    }
  }, [secondsRemaining]);

  //handlers
  const handleChangeValue = (field, value) => {
    if (field === "customer_mobile") {
      const pvalue = ("" + value).replace(/\D/g, "").slice(0, 10);
      setValue(field, pvalue, { shouldValidate: true });
    } else if (field === "otp") {      
      setValue(field, value, { shouldValidate: true });
      setOtpError(null);
    } else {
      setValue(field, value, { shouldValidate: true });
    }
  };

  

  const handleSubmitNumberForm = async (formData) => {
    //setShowOtpScreen(1);
    //return;
    const formDataJson = {
      country_itu: countryCode,
      otp_for: "apni_mandi_subscription",
      customer_mobile: formData.customer_mobile,
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}mktg-subscriptions/send-otp`,
      {
        method: "POST",
        body: JSON.stringify(formDataJson),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.errors?.length > 0) {
          HandleErrorResponse(data, setError);
        } else if (data?.success) {
          setShowOtpScreen(1);
          setSecondsRemaining(2 * 60);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSubmitOtpForm = async (formData) => {
    const otp = formData.otp;
    if (otp.toString().length != 4) {
      setOtpError("OTP is required.");
      return;
    }
    //setShowOtpScreen(2);
    //return;
    const formDataJson = {
      otp_type: formData.customer_mobile,
      otp: formData.otp,
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}auth/verify-otp`,
      {
        method: "POST",
        body: JSON.stringify(formDataJson),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.errors?.length > 0) {
          setOtpError("Invalid OTP entered.");
        } else if (data?.success) {
          setShowOtpScreen(2);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleResendOtp = async () => {
    setSecondsRemaining(2 * 60);
    setIsActive(false);
    const formDataJson = {
      country_itu: countryCode,
      otp_for: "apni_mandi_subscription",
      customer_mobile: customer_mobile,
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}mktg-subscriptions/send-otp`,
      {
        method: "POST",
        body: JSON.stringify(formDataJson),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.errors?.length > 0) {
          HandleErrorResponse(data, setError);
        } else if (data?.success) {
          setShowOtpScreen(1);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {showOtpScreen === 0 && (
        <div className={styles.login}>
          <div className={`${styles.main} ${styles.marginTop}`}>
            <div className={styles.header}>
              <Link to="/">
                <img src="/images/logo.png" alt="logo" />
              </Link>
              <h1>
                Exclusive Savings <br /> Ahead!
                <span className="polygon">
                  <PloygonIcon />
                </span>
              </h1>
              <p className="sub-heading">
                Sign up now to unlock special offers and discounts only for our
                subscribers.
              </p>
            </div>

            <form
              id="mobile-form"
              onSubmit={handleSubmit(handleSubmitNumberForm)}
            >
              <CustomInput
                label="Mobile Number"
                labelRequired={true}
                type="text"
                placeholder="Enter mobile number"
                error={Boolean(errors.customer_mobile)}
                helperText={
                  errors?.customer_mobile && errors?.customer_mobile?.message
                }
                value={mobileFormat(customer_mobile)}
                onChange={(e) => {
                  handleChangeValue(
                    "customer_mobile",
                    e?.target?.value?.trimStart().replace(/\D/, "")
                  );
                }}
                inputRef={register("customer_mobile").ref}
                capitalize={true}
              />
              <button className={styles["main-button"]}>Continue</button>
            </form>
            <div className={styles.footer}>
              <span>
                Don't have a registration yet?&nbsp;
                <Link to="/signup">Register here</Link>
              </span>
            </div>
          </div>
        </div>
      )}

      {showOtpScreen === 1 && (
        <div className={styles.login}>
          <div className={`${styles.main} ${styles.marginTop}`}>
            <div className={styles.header}>
              <Link to="/">
                <img src="/images/logo.png" alt="logo" />
              </Link>
              <div className={styles.otpHeader}>Please Enter OTP</div>
              <div className={styles.otpPara}>
                Your one time password (OTP) has been sent via SMS to your
                registered mobile number.
              </div>
              <h1>
                Exclusive Savings <br /> Ahead!
                <span className="polygon">
                  <PloygonIcon />
                </span>
              </h1>
              <p className="sub-heading">
                Sign up now to unlock special offers and discounts only for our
                subscribers.
              </p>
            </div>

            <form id="otp-form" onSubmit={handleSubmit(handleSubmitOtpForm)}>
              <div className={styles.otpContainer}>
                <OTPInput
                  value={otp}
                  onChange={(value) => {
                    handleChangeValue("otp", value);
                  }}
                  numInputs={4}
                  renderSeparator={<span></span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className="!py-2.5 font-medium otpInput"
                    />
                  )}                 
                  inputStyle={{
                    background: "#FFFFFF",
                    borderRadius: "4px",
                    margin: ".5rem",
                    color: "#464E5F",
                    padding: ".8rem .2rem",
                    width: "3rem",
                    border:"1px solid",
                    borderColor: "#667085",                    
                  }}
                  hasErrored={Boolean(errors.otp)}
                  errorStyle={{ border: "1px solid red" }}
                  focusStyle={{ outline: "none" }}
                  isInputNum
                />                
              </div>
              {otpError && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                  }}
                >
                  {otpError}
                </div>
              )}

              <button className={styles["main-button"]}>Verify OTP</button>
            </form>
            <div className={styles.footer}>
              <span>
                Didn’t receive the SMS?&nbsp;
                {isActive ? (
                  <Link to="" onClick={handleResendOtp}>
                    Click to resend
                  </Link>
                ) : (
                  <span>
                    Click to resend ({Math.floor(secondsRemaining / 60)}:
                    {secondsRemaining % 60 < 10
                      ? `0${secondsRemaining % 60}`
                      : secondsRemaining % 60}
                    )
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
      )}

      {showOtpScreen === 2 && (
        <UpdateForm
          countryCode={countryCode}
          mobile={customer_mobile}
          setSentStatus={setSentStatus}
          setDnd={setDnd}
        />
      )}
    </>
  );
}
