import React, { useState, useEffect } from "react";
import queryString from "query-string";
import PromosheetView from "../Promosheet/PromosheetView";
import { useNavigate } from "react-router";

const Campaign = () => {
  const navigate = useNavigate();

  const { id } = queryString.parse(window.location.search);

  const [apiData, setApiData] = useState();

  const fetchData = async (id = null) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}mktg-campaign/promosheet/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        //throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.message === "mktgCampaign Not Found") {
        navigate("/page-not-found");
      }
      if (data.message === "Campaign Expired") {
        navigate("/campaign-expired");
      }

      setApiData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    let decodedId;
    try {
      decodedId = atob(id);
    } catch (error) {
      if (error.name === "InvalidCharacterError") {
        console.error("Invalid character in base64 string:", error.message);
      } else {
        console.error("An error occurred while decoding:", error.message);
      }
      decodedId = "0";
    }

    fetchData(decodedId);
  }, [id]);

  return apiData ? (
    <PromosheetView apiData={apiData} />
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100dvh",
      }}
    >
      <span className="loader"></span>
    </div>
  );
};

export default Campaign;
