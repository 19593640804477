import {
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import React, { forwardRef, useState } from "react";
const StyledTextField = styled(TextField)({
  input: {
    height: "1.0375em",
    padding: "5px 14px !important",
    "&:-webkit-autofill  ": {
      WebkitBoxShadow: "0 0 0 0 100px " + "0" + " inset",
      backgroundColor: "#FFF !important;",
      backgroundClip: "content-box !important",
      borderRadius: "8px !important",
      borderColor: "red !imortant",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#fff !important",
    borderRadius: "8px",
    borderColor: "#red !imortant",
  },
  "& label.Mui-focused": {
    borderColor: "red !imortant",
  },
  "& .MuiInput-underline:after": {
    // borderBottomColor: 'green',
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "8px",
      // backgroundColor: '#FFF !important;',
    },
    "&:hover fieldset": {
      // borderColor: 'yellow',
    },
    "&.Mui-focused fieldset": {
      // borderColor: 'green',
    },
  },
  "& .MuiFormLabel-root": {
    top: "-4px",
  },
});

const CustomTextField = forwardRef((props, ref) => {
  const {
    label,
    labelRequired,
    required,
    error,
    helperText,
    variant,
    placeholder,
    disabled,
    fullWidth,
    type,
    startAdornment,
    labelOverFlow,
    endAdornment,
    ...rest
  } = props;

  //  states
  const [showPassword, setShowPassword] = useState(false);
  // Handlers
  const handleToggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      {label && (
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          required={required}
          sx={{ fontSize: "19px", fontWeight: "500", color: "#1A1009" }}
          // variant="filled"

          // variant="outlined"
        >
          {label} {labelRequired && <span className="requiredStar">*</span>}
        </InputLabel>
      )}
      <StyledTextField
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#667085",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#f47820",
            },
            "&:hover fieldset": {
              borderColor: "#f47820",
            },
          },
        }}
        // label={label}
        placeholder={placeholder}
        error={error}
        helperText={helperText || null}
        inputRef={ref}
        variant={variant}
        disabled={disabled}
        fullWidth={fullWidth}
        autoComplete={""}
        size="medium"
        /* eslint-disable no-nested-ternary */
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        InputProps={{
          startAdornment: startAdornment || null,
          endAdornment: (
            <>
              {type === "password" ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  ></IconButton>
                </InputAdornment>
              ) : (
                endAdornment || null
              )}
            </>
          ),
        }}
        {...rest}
      />
    </div>
  );
});

CustomTextField.displayName = "CustomTextField";

CustomTextField.defaultProps = {
  label: "",
  required: false,
  error: false,
  helperText: "",
  variant: "outlined",
  disabled: false,
  fullWidth: true,

  type: "text",
};

export default CustomTextField;
