// import type { AutocompleteProps as IAutocompleteProps } from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";
import React, { forwardRef } from "react";

import CustomTextField from "../CustomTextField";

// export type ISelectfield = IAutocompleteProps & {
//   options: any[];
// };

const CustomSelect = forwardRef((props, ref) => {
  const {
    label,
    labelRequired,
    size,
    placeholder,
    options,
    multiple,
    required,
    disableCloseOnSelect,
    openOnFocus,
    disabled,
    blurOnSelect,
    error,
    helperText,
    labelOverFlow,
    ...restOfSelectFieldProps
  } = props;

  return (
    <div className="flex flex-col">
      <Autocomplete
        disableCloseOnSelect={multiple && disableCloseOnSelect}
        fullWidth
      
        multiple={multiple}
        size={size}
        options={options}
        openOnFocus={openOnFocus}
        disabled={disabled}
        blurOnSelect={blurOnSelect}
        renderInput={(params) => (
          <CustomTextField
            required={required}
            label={label}
            labelRequired={labelRequired}
            {...params}
            labelOverFlow={labelOverFlow}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            {...{ ref }}
          />
        )}
        {...restOfSelectFieldProps}
      />
    </div>
  );
});

CustomSelect.defaultProps = {
  label: "",
  ref: null,
  placeholder: "",
  options: [],
  size: "medium",
  required: false,
  multiple: false,
  disableCloseOnSelect: true,
  openOnFocus: false,
  blurOnSelect: true,
  error: false,
  helperText: "",
};

export default CustomSelect;

CustomSelect.displayName = "Labelled-Select-field";
